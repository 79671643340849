import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-190a01d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-stores" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileMenuSVG = _resolveComponent("MobileMenuSVG")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["mobile-symbol", {'open': _ctx.isOpen}]),
      onClick: _cache[0] || (_cache[0] = () => { _ctx.isOpen = !_ctx.isOpen })
    }, [
      _createVNode(_component_MobileMenuSVG)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["mobile-wrapper", {'open': _ctx.isOpen}]),
      key: "mobile-wrapper",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.triggerOpen && _ctx.triggerOpen(...args)))
    }, [
      _createElementVNode("div", {
        class: "mobile-close",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.triggerOpen && _ctx.triggerOpen(...args)), ["stop"]))
      }, "X"),
      _createElementVNode("div", {
        class: "mobile-content",
        onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop","prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_router_link, {
            to: "/#subscribe",
            class: "storebtn-menu",
            onClick: _ctx.triggerOpen
          }, {
            default: _withCtx(() => [
              _createTextVNode("Подписаться")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ], 2),
    _createElementVNode("nav", {
      class: _normalizeClass(["menu", {'open': _ctx.isOpen}])
    }, [
      _createVNode(_component_router_link, {
        to: "/#main",
        class: _normalizeClass({'item': true, 'active': _ctx.activePage === 'main'}),
        onClick: _ctx.triggerOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode("Что это")
        ]),
        _: 1
      }, 8, ["class", "onClick"]),
      _createVNode(_component_router_link, {
        to: "/#ineedthis",
        class: _normalizeClass({'item': true, 'active': _ctx.activePage === 'ineedthis'}),
        onClick: _ctx.triggerOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode("Для чего это")
        ]),
        _: 1
      }, 8, ["class", "onClick"]),
      _createVNode(_component_router_link, {
        to: "/#howitworks",
        class: _normalizeClass({'item': true, 'active': _ctx.activePage === 'howitworks'}),
        onClick: _ctx.triggerOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode("Как это работает")
        ]),
        _: 1
      }, 8, ["class", "onClick"]),
      _createVNode(_component_router_link, {
        to: "/#advantages",
        class: _normalizeClass({'item': true, 'active': _ctx.activePage === 'advantages'}),
        onClick: _ctx.triggerOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode("Преимущества")
        ]),
        _: 1
      }, 8, ["class", "onClick"]),
      _createVNode(_component_router_link, {
        to: "/#whatiwillhave",
        class: _normalizeClass({'item': true, 'active': _ctx.activePage === 'whatiwillhave'}),
        onClick: _ctx.triggerOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode("Что я получу")
        ]),
        _: 1
      }, 8, ["class", "onClick"]),
      _createVNode(_component_router_link, {
        to: "/#subscribe",
        class: _normalizeClass({'item': true, 'active': _ctx.activePage === 'subscribe'}),
        onClick: _ctx.triggerOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode("Подписка")
        ]),
        _: 1
      }, 8, ["class", "onClick"]),
      _createVNode(_component_router_link, {
        to: "/#contacts",
        class: _normalizeClass({'item': true, 'active': _ctx.activePage === 'contacts'}),
        onClick: _ctx.triggerOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode("Контакты")
        ]),
        _: 1
      }, 8, ["class", "onClick"])
    ], 2)
  ], 64))
}