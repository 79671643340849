<template>
  <div class="mobile-symbol" :class="{'open': isOpen}" @click="() => { isOpen = !isOpen }"><MobileMenuSVG /></div>
  <div class="mobile-wrapper" key="mobile-wrapper" :class="{'open': isOpen}" @click="triggerOpen">
    <div class="mobile-close" @click.stop="triggerOpen">X</div>
    <div class="mobile-content" @click.stop.prevent>
      <div class="mobile-stores">
        <!-- <div class="title">СКАЧАТЬ</div> -->
        <router-link to="/#subscribe" class="storebtn-menu" @click="triggerOpen">Подписаться</router-link>
      </div>
    </div>
  </div>
  <nav class="menu" :class="{'open': isOpen}">
    <router-link to="/#main" :class="{'item': true, 'active': activePage === 'main'}" @click="triggerOpen">Что это</router-link>    
    <router-link to="/#ineedthis" :class="{'item': true, 'active': activePage === 'ineedthis'}" @click="triggerOpen">Для чего это</router-link>    
    <router-link to="/#howitworks" :class="{'item': true, 'active': activePage === 'howitworks'}" @click="triggerOpen">Как это работает</router-link>    
    <router-link to="/#advantages" :class="{'item': true, 'active': activePage === 'advantages'}" @click="triggerOpen">Преимущества</router-link>      
    <router-link to="/#whatiwillhave" :class="{'item': true, 'active': activePage === 'whatiwillhave'}" @click="triggerOpen">Что я получу</router-link>      
    <router-link to="/#subscribe" :class="{'item': true, 'active': activePage === 'subscribe'}" @click="triggerOpen">Подписка</router-link>      
    <router-link to="/#contacts" :class="{'item': true, 'active': activePage === 'contacts'}" @click="triggerOpen">Контакты</router-link>    
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import MobileMenuSVG from '@/assets/MobileMenuSVG.vue';
// import RuStoreLogoView from '@/components/RuStoreLogoView.vue';
import { sendMetrik } from '@/utils/metrika';
// import AppGalleryLogoView from '@/components/AppGalleryLogoView.vue';

export default defineComponent({
  name: 'MenuView',
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapState(['activePage'])
  },
  components: {
    MobileMenuSVG,
    // RuStoreLogoView,
    // AppGalleryLogoView,
  },
  methods: {
    sendMetrik: sendMetrik,
    triggerOpen() {
      this.isOpen = !this.isOpen;
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu {
  position: sticky;
  width: 200px;
  top: 180px;
  left: 40px;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 43px;
  font-size: 25px;
  font-weight: bold;
  font-family: "Odds", Arial, sans-serif;
  float: left;
}

.menu::before {
    content: '';
    width: 1px;
    top: 34px;
    bottom: 38px;
    background: #F1CD11;
    left: -30px;
    position: absolute;
}

.mobile-symbol {
  display: none;
}

.mobile-wrapper {
  overflow: hidden;
  height: 0;
}

.item {
  margin: 20px 0;
  position: relative;
  color: black;
  text-decoration: none;
}

.item::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #F1CD11;
    border-radius: 50%;
    top: 11px;
    left: -35px;
}

.menu .active {
  color: #6DC527;
}

.active::before {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  top: 5px;
  left: -39.5px;
  border: 4px solid #6DC527;
}


@media (min-width: 1366px) and (max-width: 1920px) {
  .menu {
    top: 154px;
    font-size: 18px;
  }

  .menu::before {
    top: 31px;
    bottom: 29px;
  }

  .menu .item {
    margin: 15px 0;
  }
  .item::before {
    top: 6px;
  }

  .item.active::before {
    top: 1px;
  }
}


@media (min-width: 1024px) and (max-width: 1366px) {
  .menu {
    width: 150px;
    top: 120px !important;
    font-size: 18px;
  }

  .menu::before {
    top: 24px;
    bottom: 24px;
  }

  .menu .item {
    margin: 10px 0;
  }
  .item::before {
    top: 6px;
  }

  .item.active::before {
    top: 2px;
  }
}


@media (max-width: 1024px) {
  .menu {
    position: fixed;
    display: none;
    width: auto;
    top: 40px;
    left: 35%;
    z-index: 15;
    flex-direction: column;
    font-size: 18px;
  }

  .menu::before {
    top: 21px;
    bottom: 21px;
  }

  .menu .item {
    margin: 10px 0;
  }

  .menu .item::before {
    top: 4px;
  }

  .menu .item.active::before {
    top: 0px;
  }

  .mobile-symbol {
    display: block;
    cursor: pointer;
    position: fixed;
    right: 20px;
    top: 30px;
    z-index: 2;
  }

  .open.mobile-wrapper {
    overflow: visible;
    height: auto;
  }

  .open.menu {
    display: flex;  
  }

  .mobile-wrapper { 
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: var(--gray-transparent-color);
    z-index: 10;
  }

  .mobile-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 11;
  }

  .mobile-content {
    background: white;
    width: 77%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  .mobile-stores {   
    position: fixed;
    bottom: -100%;
    right: 0;
    cursor: pointer;
    z-index: 11;
    width: auto;
    left: 23%;
    background: linear-gradient(45deg, var(--light-green-color) 25%, var(--green-color) 75%);
    padding: 40px;
    text-align: center;
    flex-direction: column;
  }

  .mobile-stores.open {
    bottom: 0;
  }

  .title {
    font-family: "Odds";
    color: white;
  }

  .storebtn-menu {
    display: block;
    width: 100%;
    cursor: pointer;
    background: white;
    padding: 10px 20px;
    border: none;
    margin: 10px 0;
    border-radius: 5px;
    font-family: "Odds", Arial, sans-serif;
    font-size: 30px;
    text-decoration: none;
    color: var(--green-color);
    text-align: center;

    box-sizing: border-box;
  }
  .storebtn-menu > svg {
    height: 30px;
    width: auto;
  }

}
</style>