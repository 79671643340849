<template>
  <div class="root" id="subscribe" ref="subscribe" v-observe-visibility="(isVisible: Boolean) => {  if (isVisible) setActiveMenuItem('subscribe') }">
    <h1>Позвольте себе стать лучше в общении и знакомствах! Перейдите от страха к уверенности вместе с нами!</h1>
    <div class="columns">
      <div><p>Скачайте «Белая ворона» сейчас и начните путешествие к уверенному общению! Попробуйте приложение бесплатно и почувствуйте разницу сами.</p>
        <div class="form">
          <div>
            <p class="text">Подпишитесь на обновления, чтобы узнавать все новости приложения первым</p>
            <div class="checkbox first">
              <input type="checkbox" class="checkbox" id="politics" v-model="politics" />
              <label class="politics" for="politics">Я согласен с политикой конфиденциальности сайта</label>
            </div>
          </div>
          <div class="form-fields">
            <input placeholder="E-mail" v-model="email" />
            <div class="checkbox second">
              <input type="checkbox" class="checkbox" id="politics-second" v-model="politics" />
              <label class="politics" for="politics-second">Я согласен с политикой конфиденциальности сайта</label>
            </div>
            <button class="btn-yellow" type="submit" @click.prevent="onSend" :disabled="email === '' || !isEmailCorrect() || !politics">Подписаться</button>
          </div>
        </div>
      </div>
      <CrowFormContactsSVG class="crow" />
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations, mapActions } from 'vuex';
import { sendMetrik } from '../utils/metrika';
import CrowFormContactsSVG from '@/assets/CrowFormContactsSVG.vue';
import { checkEmail } from '@/utils/email';

export default defineComponent({
  name: 'SubscribeView',
  data: () => {
    return {
      email: '',
      politics: true,
    }
  },
  methods: {
    ...mapMutations(['setActiveMenuItem']),
    sendMetrik: sendMetrik,
    isEmailCorrect() {
      return checkEmail(this.email);
    },
    ...mapMutations([
        'showAlert'
    ]),
    ...mapActions([
        'subscribe',
    ]),
    async onSend() {
        const result = await this.subscribe({
          email: this.email,
        })
        
        if (result.status === 201) {
          this.sendMetrik('subscribe-news');
            this.showAlert({
                text: 'Вы подписаны на новости о проекте. Пожалуйста подтвердите подписку на почте!',
                class: 'alert-success'
            });

            this.email = '';
            this.politics = false;
        }
    }
  },
  components: {
    CrowFormContactsSVG,
  }
});
</script>

<style scoped>
.root {
}

.columns {
display: flex;
flex-direction: row;
}

.crow {
width: 30%;
min-width: 210px;
margin-left: 10%;
}

.form {
  background: linear-gradient(45deg, var(--light-green-color) 25%, var(--green-color) 75%);
  padding: 40px 60px;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form > div {
width: 48%;
}

.form-fields {
display: flex;
flex-direction: column;
}

.text {
  font-family: "Odds", Arial, sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
  margin-top: 10px;
}

.checkbox.second {
  display: none;
}

@media (max-width: 1024px) {
  .columns {
    flex-direction: column;
  }

  .form {
    flex-direction: column;
    padding: 20px 30px;
  }

  .form > div {
    width: 100%;
  }

  .checkbox {
    margin-bottom: 5px;
  }

  .crow {
    display: none;
  }

  .checkbox.first {
    display: none;
  }

  .checkbox.second {
    display: flex;
  }
}

</style>